import React from "react";
import styled from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//COMPONENTS
import DropWrapper from "../../components/UI/DropWrapper";
import SimpleStats from "../../components/UI/SimpleStats";
import Image from "../../components/UI/Image";
//GENERIC STYLE IMPORT
import {Heading, Paragraph} from "../../components/UI/Typography";
import DropTestimonial from "../../images/case_studies/enel/drop_testimonial.svg";
import DropRtlSvg from "../../images/bkg_drop_rtl.svg";
import BlueDrop from '../../images/case_studies/enel/BlueDrop.svg';
import BKGImg from "../../images/case_studies/enel/enel_bkg_1.jpg";
import HeroCaseStudy from "../../components/HeroCaseStudy";
import LottieController from "../../components/UI/LottieController";
import Graph1 from '../../components/UI/Lottie/Occhio.json';
import Graph2 from '../../components/UI/Lottie/Obiettivi.json';
import Graph3 from '../../components/UI/Lottie/Colleghi.json';
import Graph4 from '../../components/UI/Lottie/Microfono.json';
import {useInView} from "react-intersection-observer";
import DropBg from "../../components/UI/DropBg";
import useDimensions from 'react-use-dimensions';
import LutherLogo from '../../images/case_studies/enel/luther_logo.svg';
import SEO from "../../components/SEO";
import {Fade} from 'react-reveal';
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage";
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import Results from "../../components/CaseStudy/Results";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const StatsDiv = styled.div`
    margin-top: 32px;
    h3{
    color:#1D0F41;
    font-weight:700;
    font-style:normal;
    font-size:32px;
    margin:0px;
    }
    p{
        color:#00446A;
        font-size:18px;
        font-weight:700;
    }
`

const CompanyStats = () => {
    return <StatsDiv>
        <h3>
            8000+
        </h3>
        <p>
            <FormattedMessage id={'enel.stats_1_msg'}/>
        </p>
    </StatsDiv>
}


const EnelCaseStudy = ({intl}) => {
    const [graphView1, inViewG1] = useInView();
    const [graphView2, inViewG2] = useInView();
    const [graphView3, inViewG3] = useInView();
    const keywords = intl.formatMessage({id: 'enel.meta_keywords'}).split(',') || [];


    return (
        <>
            <SEO title={intl.formatMessage({id: 'enel.meta_title'})}
                 description={intl.formatMessage({id: 'enel.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>
            <HeroCaseStudy categories={["portfolio.filters.design_dev"]}
                hasPartner={true} partnerLogo={LutherLogo} heroImage={'case_studies/enel/Header-Enel.png'} number={'8000+'} title={'enel.title'}
                           stats={<CompanyStats/>} subtitle1={'enel.subtitle'} intl={intl}
                           color={'linear-gradient(45deg, #1B527F 0%, #41B9E6 100%)'}/>
            <div className="section-inner-padding">
                <DropWrapper svgfile={DropRtlSvg} position="left" top="140%"/>
                <DropBg src={BlueDrop} bottom={'20%'} left={'20%'} className="d-none d-lg-block"/>

                    <div className="container">
                        <div className="row">
                            <Fade bottom delay={200}>
                                <div className="col-12">
                                    <SectionTitle number={'01'} text={'enel.overview_title'}/>
                                </div>
                            </Fade>
                        </div>
                        <div className="row">
                            <Fade bottom delay={400}>
                                <div className={"col-md-5 col-12"}>
                                    <Heading level={"3"}>
                                        <FormattedMessage id={"enel.client_title"}/>
                                    </Heading>
                                    <Paragraph className="pt-2">
                                        <FormattedMessage id={"enel.client_1"}/>
                                    </Paragraph>
                                </div>
                            </Fade>
                            <Fade bottom delay={800}>
                                <div className={"col-md-6 col-12 offset-md-1"}>
                                    <Heading level={"4"} className="pt-2">
                                        <FormattedMessage id={"enel.client_needs"}/>
                                    </Heading>
                                    <Paragraph className="pt-3">
                                        <FormattedMessage id={"enel.client_needs_1"}/>
                                    </Paragraph>
                                    <Paragraph className="pt-2">
                                        <FormattedMessage id={"enel.client_needs_2"}/>
                                    </Paragraph>
                                </div>
                            </Fade>
                        </div>
                    </div>

            </div>
            <FullWidthImage image={BKGImg} imageWidth={1440} imageHeight={702}/>
            <div className="section-padding">
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 d-none d-md-block pl-5">
                                <SectionTitle number={'02'} text={'enel.brief_title'}/>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"enel.service_big_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"enel.services_txt_1"}/>
                                </Paragraph>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"enel.services_txt_2"}/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className="col-md-7 col-sm-12">
                                <SectionTitle number={'03'} text={'enel.workflow_title'}/>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"enel.solutions_big_title"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"enel.solutions_txt_1"}/>
                                </Paragraph>
                                <Heading level={"5"} margin={'56px 0px 0px 0px'}>
                                    <FormattedMessage id={"enel.solutions_subtitle"}/>
                                </Heading>
                                <Paragraph className="pt-2" margin={'0px'}>
                                    <FormattedMessage id={"enel.solutions_txt_2"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-4 col-sm-12 offset-0 offset-md-1 position-relative">
                                <Image filename={'case_studies/enel/drop_solutions.jpg'} className="case-study-right-pic"
                                       alt={'drop solutions'}/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className="col-12 col-lg-5 order-last order-lg-first position-relative">
                                <Image
                                    filename="case_studies/enel/drop_mobile.jpg"
                                    alt="drop"
                                    className="case-study-left-pic"
                                />
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-12 col-lg-6 offset-lg-1 order-first order-lg-last">
                                <Heading level="4">
                                    <FormattedMessage id={"enel.solutions_title_3"}/>
                                </Heading>
                                <Paragraph className="pt-2 pr-lg-4">
                                    <FormattedMessage id={"enel.solutions_txt_5"}/> <br/>
                                    <FormattedMessage id={"enel.solutions_txt_5_2"}/>
                                </Paragraph>
                                <Paragraph className="pt-2 pr-lg-5">
                                    <FormattedMessage id={"enel.solutions_txt_6"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>

            </div>
            <div id="04" className="section-padding-bottom">
                    <div className="container">
                        <div className="row">
                            <Fade bottom delay={400}>
                                <div className="col-12 col-lg-5">
                                    <Paragraph className="mb-5 mb-md-0">
                                        <FormattedMessage id={"enel.solutions_txt_7"}/>
                                    </Paragraph>
                                    <Heading level={"4"}>
                                        <FormattedMessage id={"enel.solutions_title_4"}/>
                                    </Heading>
                                    <Paragraph className="pt-2">
                                        <FormattedMessage id={"enel.solutions_title_5"}/>
                                    </Paragraph>
                                </div>
                            </Fade>
                            <Fade bottom delay={800}>
                                <div className="col-12 col-lg-6 offset-lg-1 d-flex align-items-center justify-content-center">
                                    <div className="testimonial-wrapper">
                                        <img src={DropTestimonial} alt={'testimonial'} className="testimonial-drop"/>
                                        <div className="sentence-container">
                                            <p className="sentence">
                                                <FormattedMessage id="enel.testimonial_1"/>
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center testimonial">
                                                <div className="testimonial-img">
                                                    <Image filename={"francesco_monti.jpg"} alt={"Francesco Monti"} style={{display: 'block'}}/>
                                                </div>
                                                <div>
                                                    <Paragraph italic customColor={'blueHoki'} bold>
                                                        <FormattedMessage id={"enel.testimonial_name"}/>
                                                    </Paragraph>
                                                    <Paragraph italic customColor={'blueHoki'}>
                                                        <FormattedMessage id={"enel.testimonial_desc"}/>
                                                    </Paragraph>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>

            </div>
            <Results
                subtitle="enel.dark_section_paragraph"
                testimonialQuote="enel.dark_section_quote"
                testimonialImg="matteo_pulcrano.jpg"
                testimonialName="enel.dark_section_testimonial_name"
                testimonialRole="enel.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView1}>
                        <LottieController
                            lottieJSON={Graph1}
                            inView={inViewG1}
                            height="100px"
                            width="86px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG1}
                            valueFrom={0}
                            valueTo={735}
                            headingStyle={{fontWeight: 500}}
                            measureUnit="K"
                            label="enel.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView2}>
                        <LottieController
                            lottieJSON={Graph2}
                            inView={inViewG2}
                            height="100px"
                            width="122px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG2}
                            valueFrom={0}
                            valueTo={29}
                            headingStyle={{fontWeight: 500}}
                            label="enel.stats_2"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView2}>
                        <LottieController
                            lottieJSON={Graph3}
                            inView={inViewG2}
                            height="100px"
                            width="116px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG2}
                            valueFrom={0}
                            valueTo={8000}
                            headingStyle={{fontWeight: 500}}
                            label="enel.stats_3"
                            headingLevel="1" l
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView3}>
                        <LottieController
                            lottieJSON={Graph4}
                            inView={inViewG3}
                            height="100px"
                            width="122px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG3}
                            valueFrom={0}
                            valueTo={14}
                            label="enel.stats_4"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="enel.next_success_case_title"
                desc="enel.next_success_case_description"
                link="/case-study/acea/"
                intl={intl}
            />
        </>
    );
};

const customProps = {
    localeKey: "enel", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(EnelCaseStudy));
